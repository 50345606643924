export const newConfig = [
    {
        head: "",
        body: [
          {
            type: "component",
            component: "InspectionReport",
            key: "FieldReports",
            withoutLabel: true,
            hideInCitizen: true,
          }
        ]
      },
  ];