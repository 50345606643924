export const newConfig = [
  {
    head: "ES_NEW_APPLICATION_PROPERTY_ASSESSMENT",
    body: [
      {
        route: "home",
        component: "RGAEDCRForm",
        nextStep: "",
        hideInEmployee: true,
        key: "ScrutinyDetails",
        texts: {
          headerCaption: "",
          header: "Regularisation Plan Scrutiny",
          cardText: "Provide the details required for Regularisation Plan Scrutiny",
          submitBarLabel: "EDCR_SCRUTINY_SUBMIT_BUTTON",
          skipText: "EDCR_CLEAR_FORM",
        },
      },
    ],
  }
];
