import React from 'react'

import ApplicationSearchFormField from './ApplicationSearchFormField'

function DashboardApplicationSearch() {
  return (
   <ApplicationSearchFormField/>

  )
}

export default DashboardApplicationSearch